<script setup lang="ts">
import { ref } from 'vue';

const clients = ref([
  { tip: 'Bip Consulting', src: new URL('/src/assets/clientsLogo/bip-logo-white.webp', import.meta.url).href },
  { tip: 'Propeller Heads', src: new URL('/src/assets/clientsLogo/Propeller-heads-logo-white.webp', import.meta.url).href },
  { tip: 'DeCash', src: new URL('/src/assets/clientsLogo/Decash-logo-white.webp', import.meta.url).href },
  { tip: 'PV01', src: new URL('/src/assets/clientsLogo/PV01-logo-white.webp', import.meta.url).href },
  { tip: 'Labtrace', src: new URL('/src/assets/clientsLogo/LabTrace-logo-white.webp', import.meta.url).href }
]);
</script>

<template>
  <div class="flex-1 w-full">
    <h4>{{ $t('message.trustedBy') }}</h4>
    <div class="bg-secondary mt-4 md:mt-7 py-5 md:py-10 grid grid-cols-5 place-items-center gap-1 md:gap-14">
      <div v-for="client in clients" :key="client.tip" class="tooltip tooltip-info p-2" :data-tip="client.tip">
        <img class="max-h-12" :src="client.src" />
      </div>
    </div>
  </div>
</template>
