<script setup lang="ts">
import { ref } from 'vue'
import i18n from '../i18n'

const isLanguageEnglish = ref(true)

const changeLanguage = () => {
  if (isLanguageEnglish.value) {
    i18n.global.locale = 'it'
  } else {
    i18n.global.locale = 'en'
  }
}

i18n.global.locale = 'en'
</script>
<template>
  <label class="swap">
    <input type="checkbox" v-model="isLanguageEnglish" @click="changeLanguage" />
    <div class="swap-on">EN</div>
    <div class="swap-off">IT</div>
  </label>
</template>
