<script setup lang="ts">
import { ref } from 'vue';

const blockchains = ref([
  { tip: 'Bitcoin', src: new URL('/src/assets/blockchainLogo/btc.png', import.meta.url).href },
  { tip: 'Ethereum', src: new URL('/src/assets/blockchainLogo/eth.webp' , import.meta.url).href },
  { tip: 'Cronos', src: new URL('/src/assets/blockchainLogo/cronos.png', import.meta.url).href },
  { tip: 'Algorand', src: new URL('/src/assets/blockchainLogo/algo.png', import.meta.url).href },
  { tip: 'Avalanche', src: new URL('/src/assets/blockchainLogo/avax.png', import.meta.url).href },
  { tip: 'BSC', src: new URL( '/src/assets/blockchainLogo/bsc.png', import.meta.url).href },
  { tip: 'Polygon', src: new URL( '/src/assets/blockchainLogo/polygon.png', import.meta.url).href },
  { tip: 'Polkadot', src: new URL('/src/assets/blockchainLogo/polka.png', import.meta.url).href },
  { tip: 'Fantom', src: new URL('/src/assets/blockchainLogo/ftm.png', import.meta.url).href },
  { tip: 'Optimism', src: new URL('/src/assets/blockchainLogo/opt.png', import.meta.url).href },
  { tip: 'Arbitrum', src: new URL('/src/assets/blockchainLogo/arb.png', import.meta.url).href },
  { tip: 'ZkSync', src: new URL('/src/assets/blockchainLogo/zksync.png', import.meta.url).href }
]);
</script>

<template>
  <div class="h-max w-full">
    <div class="items-center">
      <h4>{{ $t('message.chainsWeLike') }}</h4>
      <div
        class="
          grid
          grid-cols-3
          md:grid-cols-6
          w-full
          h-auto
          bg-white
          bg-opacity-10
          backdrop-blur-sm
          place-items-center
          gap-9
          px-2
          py-4
          md:px-10
          md:py-12
          mt-4
          md:mt-7
          shadow-customSmall
          shadow-secondary
        "
      >
        <div
          v-for="blockchain in blockchains"
          :key="blockchain.tip"
          class="tooltip tooltip-success p-2"
          :data-tip="blockchain.tip"
        >
          <img
            :src="blockchain.src"
            class="rounded-full h-16 md:h-20 hover:scale-105"
          />
        </div>
      </div>
    </div>
  </div>
</template>
