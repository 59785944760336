import { createI18n } from 'vue-i18n'
import { messages } from './services/messages'

const i18n = createI18n({
  locale: 'it',
  fallbackLocale: 'en',
  messages: messages
})

export default i18n
