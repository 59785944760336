<script setup lang="ts">
</script>
<template>
  <div class="h-max">
    <div class="items-center p-6 md:p-14">
      <div class="w-auto h-52 rounded-3xl shadow-2xl p-3 md:p-7 relative overflow-hidden">
        <div
          class="items-center absolute inset-0 bg-cover bg-center bg-no-repeat backdrop-filter backdrop-blur-sm"
          style="background-image: url(/glitch.jpeg)"
        ></div>
        <div class="items-center absolute inset-0 backdrop-filter backdrop-blur-sm">
          <div class="flex flex-col h-full justify-center items-center">
            <h1 class="text-4xl text-center text-white font-bold mb-6">{{ $t('message.contactBox.title') }}</h1>
            <router-link to="/contact">
              <button class="btn btn-ghost items-center font-medium text-lg tracking-wider">
                {{ $t('message.contactBox.button') }}
              </button>

            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
