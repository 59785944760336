<script setup lang="ts">
import { computed } from 'vue';
import Button from '../components/Button.vue';

type SectionTypes = 'ctaBoxTelegram' | 'ctaBoxMain';

const props = defineProps<{
  section: SectionTypes;
}>();

const titleKey = computed(() => `message.${props.section}.title`);
const subTitleKey = computed(() => `message.${props.section}.subTitle`);
const isTelegramBox = computed(() => props.section === 'ctaBoxTelegram');
</script>

<template>
  <div class = "flex flex-col items-center w-full"
  :class="[ isTelegramBox ? 'mb-32' : 'bg-white bg-opacity-10 backdrop-blur-sm shadow-customSmall shadow-secondary']">
    <div class="flex flex-col items-center md:w-2/5 gap-7 px-2 py-4 md:px-10 md:py-12">
      <h4 v-if="isTelegramBox" class="uppercase">
        {{ $t(titleKey) }}
      </h4>
      <h2 v-else>
        {{ $t(titleKey) }}
      </h2>
      <h5>{{ $t(subTitleKey) }}</h5>
      <Button :cta="isTelegramBox ? 'telegram' : 'getInTouch'" />
    </div>
  </div>
</template>