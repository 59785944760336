import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import AboutUsView from '../views/AboutUsView.vue'
import ClientsView from '../views/ClientsView.vue'
import ContactUsView from '../views/ContactUsView.vue'
import HomeView from '../views/HomeView.vue'
import AuditView from '../views/services/AuditView.vue'
import ConsultingView from '../views/services/ConsultingView.vue'
import ExtendedTeamView from '../views/services/ExtendedTeamView.vue'
import PoCView from '../views/services/PoCView.vue'
import ProjectDevView from '../views/services/ProjectDevView.vue'
import ServicesView from '../views/ServicesView.vue'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutUsView
  },
  {
    path: '/clients',
    name: 'clients',
    component: ClientsView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactUsView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/services/projectDevelopment',
    name: 'projectDevelopment',
    component: ProjectDevView
  },
  {
    path: '/services/audit',
    name: 'audit',
    component: AuditView
  },
  {
    path: '/services/consulting',
    name: 'consulting',
    component: ConsultingView
  },
  {
    path: '/services/extendedTeam',
    name: 'extendedTeam',
    component: ExtendedTeamView
  },
  {
    path: '/services/PoC&MVP',
    name: 'PoC&MVP',
    component: PoCView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
