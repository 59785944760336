<script setup lang="ts">
import router from '../router';


type ctaButtonTypes = 'getInTouch' | 'telegram';

const props = defineProps<{
  cta: ctaButtonTypes
}>();

const TELEGRAM_URL = "https://t.me/mp_shadowy";

const determineRoute = () => {
  console.log(props.cta)
  switch (props.cta) {
    case 'getInTouch':
      return '/contact';
    case 'telegram':
      return TELEGRAM_URL;
  }
};

const handleButtonClick = () => {
  const route = determineRoute();
  if (route === TELEGRAM_URL) {
    window.open(TELEGRAM_URL, "_blank");
  } else {
    router.push(route)
  }
};

const ctaKey = `message.button.${props.cta}`;

</script>

<template>
  <a @click=handleButtonClick()>
    <button :class="[
      'btn',
      'text-base',
      'btn-primary',
      'border',
      'border-white',
      'text-xl',
      'md:text-2xl',
      'font-medium',
      'uppercase',
      'tracking-wider',
      props.cta === 'telegram' ? 'shadow-customSmall shadow-secondary' : '']">
      {{ $t(ctaKey) }}
    </button>
  </a>
</template>
