// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAZ5Os2j9uQ-ns1KdKDmWwfps32tEmb1Jg',
  authDomain: 'shadowywebsite.firebaseapp.com',
  databaseURL: 'https://shadowywebsite-default-rtdb.firebaseio.com',
  projectId: 'shadowywebsite',
  storageBucket: 'shadowywebsite.appspot.com',
  messagingSenderId: '589097192663',
  appId: '1:589097192663:web:8633f3c00dc53ad41c3492',
  measurementId: 'G-0RPNZWH8RZ'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export { db }

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';

// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const db = firebaseApp.firestore();

// export { db };
