<script setup lang="ts">
import { computed } from 'vue';

type SeviceCardTypes = "consulting" | "development" | "audit" | "poc" | "team";

const props = defineProps<{
  service: SeviceCardTypes
}>()

const logoSrc = computed(() => new URL(`/src/assets/images/services/${props.service}.webp`, import.meta.url).href);
const titleKey = computed(() => `message.services.cards.${props.service}.title`);
const bodyKey = computed(() => `message.services.cards.${props.service}.body`);

const determineRoute = () => {
  switch (props.service) {
    case "consulting":
      return "/services/consulting";
    case "development":
      return "/services/projectDevelopment";
    case "audit":
      return "/services/audit";
    case "poc":
      return "/services/PoC&MVP";
    case "team":
      return "/services/extendedTeam";
    default:
      return "";
  }
};

const handleClick = () => {
  const route = determineRoute();
  return route
};

</script>

<template>
  <div class="card bg-slate-800 shadow-xl">
    <div class="card-body p-1">
      <img :src="logoSrc" class="rounded-xl w-full h-auto object-cover sm:h-72" />
      <div class="card-title text-center">
        <p class="text-white text-lg md:text-2xl whitespace-pre-line my-2">
          {{ $t(titleKey) }}
        </p>
      </div>
      <p class="p-3 font-thin text-md md:text-lg whitespace-pre-line text-center leading-relaxed">
        {{ $t(bodyKey) }}
      </p>
      <router-link :to="handleClick()">

        <p class="p-3 font-bold text-end link-primary cursor-pointer">
          {{ $t('message.button.findMore') }}
        </p>
      </router-link>
    </div>
  </div>
</template>