export const messages = {
  en: {
    message: {
      navbar: {
        home: 'Home',
        about: 'About Us',
        services: 'Services',
        servicesOptions: {
          all: 'All Services',
          consulting: 'Consulting',
          development: 'Project Development',
          poc: 'PoC & MPV development',
          audit: 'Security Audit',
          extended: 'Extended Team'
        },
        clients: 'Clients',
        contact: 'Contact Us'
      },
      reviews: {
        title: 'What our customers are saying?',
        DeCash: {
          description:
            "DeCash is the first independent and easy-to-use Stablecoin which combines transparent reserve management with the benefits of the world's most traded fiat currencies",
          review:
            "“Collaborating with Shadowy Creators was a pivotal moment for Decash. As we envisioned a unique, user-friendly stablecoin, they provided the technical prowess to turn that vision into reality. Their commitment to excellence, combined with punctuality and deep blockchain understanding, ensured a product that stands out in the market. We couldn't have asked for a better partner for our journey.”",
          team: '— Decash Team'
        },
        PV01: {
          description:
            "PV01 combines traditional capital expertise with digital asset know-how to develop a thriving debt capital market. Handpicked from TradFi and DeFi, they're poised to shape the future of debt markets.",
          review:
            '"Partnering with Shadowy Creators was transformative for PV01. As we sought to meld traditional capital know-how with the digital realm, they offered unmatched technical acumen. Their dedication to perfection, timely deliverables, and profound grasp of blockchain dynamics solidified a market-leading platform for us. In our mission to revolutionize debt markets, they proved to be an invaluable ally."',
          team: '— PV01 Team'
        }
      },
      exploreCustomers: 'Explore Our Customers >',
      trustedBy: 'Trusted By',
      chainsWeLike: 'Blockchains we love <3',
      ctaBoxMain: {
        title: "let's have a chat and find out how!",
        subTitle:
          'Discover how Shadowy Creators can elevate your vision through blockchain - get in touch with us, no strings attached.'
      },
      ctaBoxTelegram: {
        title: 'want a faster answer?',
        subTitle: 'Send us a message directly on Telegram!',
        shootMessage: 'Or shoot us a message ',
        directlyTelegram: 'directly on Telegram!'
      },
      contactBox: {
        title: `Let's do something great!`,
        button: `Contact us`
      },
      button: {
        getInTouch: 'Get in touch',
        contactUs: 'Contact us',
        findMore: 'Find more',
        telegram: 'Telegram'
      },
      whyShadowy: {
        whyShadowy1: {
          title: 'Why Shadowy Creators',
          body: `Discover a world where blockchain and distributed ledger technologies are transforming businesses and industries.
        At Shadowy Creators, we harness the power of these cutting-edge solutions to help your company thrive in today's
        rapidly evolving digital landscape.
        We help you figuring out the requirements, we set deadlines, we deliver. It's really that simple. We are not the crappy consultant you are used to.`
        }
      },
      home: {
        title: 'Crafting Tailor-Made Blockchain Solutions',
        subtitle: "More than just developers. We're your Blockchain co-pilots, steering your business to new heights."
      },
      aboutUs: {
        title: 'Crafting Tailor-Made Blockchain ',
        subtitle: `We work on the most advanced Web3, crypto and SW projects
        We work on innovative ideas and support talented people to launch their projects`,
        firstBlockTitle: 'Decentralized Software House',
        firstBlockBody: `We set our priorities according to Shadowy Coders best returns.
        Freedom and ownership over task management, time and personal activities are the perfect incentive for
        quality results.
        Every Shadowy Coder knows what to do and how.
        100% distributed Up-time.`,
        secondBlockTitle: 'Improvements Chain',
        secondBlockBody: `A never ending flow of new skills and knowledges allows us to improve day by day.
        This is the only way to be able to operate and be competitive in this very dynamic and disruptive technology industry.`
      },
      services: {
        title: 'Discover our services',
        subtitle: 'Hi-Tech Blockchain solutions',
        titleBody: 'We will provide our Tech Know-How for your ideas and projects',
        titleCards: 'We can help you level up!',
        cards: {
          consulting: {
            title: `Blockchain\nConsulting`,
            body: 'We will help you understand the Blockchain Technology related to your ideas and projects'
          },
          development: {
            title: 'Project\nDevelopment',
            body: 'We will provide to you our experience and know-how.\nCTO-as-a-Service'
          },
          audit: {
            title: 'Security\nAudit',
            body: `Comprehensive security assessment of your smart contract and blockchain code to identify vulnerabilities and 
            recommend ways to fix them`
          },
          poc: {
            title: 'PoC & MVP',
            body: 'We ideate a potential solution (PoC) and if viable we develop the prototype to the best (MVP)'
          },
          team: {
            title: 'Extended\nTeam',
            body: 'Our higly skilled developers will join your team!'
          }
        }
      },
      consulting: {
        title: 'Blockchain Consulting',
        subTitle: 'Turning your idea into reality',
        titleBody: 'We can help you to turn your high-level idea into something that would support future development',
        roleTitle: 'Our Role',
        roleBody: `Blockchain technology and Web3 platforms are transforming the way businesses operate in the digital era. We
        offer expert consulting services to help you leverage the power of these cutting-edge technologies. Our team of
        experienced blockchain and Web3 developers will work with you to understand your business needs and design
        customized solutions that can help you streamline operations, enhance security, and unlock new revenue streams.
        We know and work with all kinds of blockchain and web3 implementation.
        We have the expertise and knowledge to guide you every step of the way.`,
        roadmapTitle: 'Operative Roadmap',
        firstStep: {
          title: 'Ideation Assessment',
          body: `We offer an initial assessment service to evaluate your idea. We understand the importance of a solid
          foundation and we want to ensure that your idea is feasible, technically sound and meets your business
          objectives. Our team will perform an in-depth analysis of your concept and provide valuable feedback and
          suggestions to help you refine your blockchain strategy.`
        },
        secondStep: {
          title: 'Discovery/Architecture Phase',
          body: `Our discovery/architecture phase is designed to help you define the requirements of your solution. Our team
          will work with you to identify the key features, functionalities, and architecture needed to bring your
          blockchain/Web3 solution to life. We use the latest technologies and frameworks to develop a robust and
          scalable architecture and a high-level development roadmap that will meet your current and future business
          needs.`
        },
        thirdStep: {
          title: 'Wireframe Designs',
          body: `Once the discovery/architecture phase is complete, we move on to creating wireframes that capture the layout
          and structure of your blockchain solution. Our team of skilled UX and UI designers will work with you to
          create wireframes that are visually appealing and intuitive to use. We aim to strike a balance between
          aesthetics and usability, ensuring that your blockchain solution not only looks great but is also
          user-friendly.`
        }
      },
      development: {
        title: 'Project\nDevelopment',
        subTitle: 'End-to-End Blockchain development',
        titleBody: `We determine the best way to introduce blockchain and Web3 into your operations or services, and also
        determine the correct technical approach to the development and deployment.`,
        roleTitle: 'Our Role',
        roleBody: `We are experts in blockchain and web3 project development. We understand that these technologies can be complex,
        which is why we offer end-to-end project development services to bring your ideas to life. Our team of skilled
        developers has a deep understanding of blockchain and web3 technologies and is committed to delivering
        high-quality solutions that meet your specific needs. From concept to launch, we work with you every step of the
        way to ensure that your project is a success. We offer a range of development services, including smart contract
        development, decentralized application (dApp) development, and token development (Fungible and Non-Fungible).
        Whether you need a custom solution or want to build on an existing blockchain platform, we have the expertise to
        deliver the results you need. With our blockchain and web3 project development services, you can trust that we
        will handle all aspects of your project, including design (UI ad UX), development, testing, and deployment. Our
        goal is to exceed your expectations and help you realize the full potential of these exciting technologies.`,
        roadmapTitle: 'Operative Roadmap',
        firstStep: {
          title: 'Business Analysis & Requirements Specification',
          body: `We start with a comprehensive analysis of your business requirements to ensure that we fully understand your
          needs and objectives. Our team will work closely with you to identify the key features and functionalities
          that your blockchain solution requires. We'll also consider factors such as scalability, security, and
          regulatory compliance to ensure that your solution meets all of your business requirements.`
        },
        secondStep: {
          title: 'Technical Specifications',
          body: `Once we have a clear understanding of your requirements, we'll start to develop the technical architecture
          for your blockchain solution. Our team of experienced architects will create a robust and scalable
          architecture that meets your business needs. We'll also provide detailed technical documentation to ensure
          that your team has a clear understanding of the solution's architecture.`
        },
        thirdStep: {
          title: 'UI/UX Design',
          body: `We understand the importance of user experience in the success of a blockchain solution. Our team of
          designers will work with you to create an intuitive and visually appealing user interface that meets your
          business needs. We'll create wireframes and prototypes to ensure that the user experience is optimized
          before moving onto development.`
        },
        fourthStep: {
          title: 'Smart Contract Development',
          body: `Smart contracts are a critical component of any blockchain solution, and our team has extensive experience
          in smart contract development. We'll create smart contracts that are secure, efficient, and meet your
          specific business needs. Our team will also perform extensive testing to ensure that your smart contracts
          are bug-free and meet all of your requirements.`
        },
        fifthStep: {
          title: 'Frontend and Backend Development',
          body: `Once the smart contracts are developed, we'll move onto the frontend and backend development. Our team of
          skilled developers will create a responsive and scalable frontend that delivers an exceptional user
          experience. We'll also develop a robust backend that handles data storage, integration with external
          systems, and other essential functions.`
        },
        sixthStep: {
          title: 'Infrastructure Setup and Maintenance',
          body: `After development is complete, we'll set up the infrastructure needed to deploy your blockchain solution.
          We'll ensure that the infrastructure is secure, scalable, and optimized for performance. We'll also provide
          ongoing maintenance and support to ensure that your solution remains stable and secure.`
        }
      },
      poc_MVP: {
        subTitle: 'Prototyping before success',
        titleBody: `We provide a quick and cost-efficient way to assess an idea in a safe environment with a solid PoC.
        Fast market entry with a well-designed MVP.`,
        roleTitle: 'Our Role',
        roleBody: ` Prototyping a system or a solution is one of the most important enterprise blockchain services that a
        professional company can offer. Building blockchain and web3 applications can be a complex and challenging
        process, in fact, this is precisely why prototyping is not only crucial for testing the viability of a concept,
        it can also play a crucial role for the success of a project by laying a solid foundation to support further
        development. That's why we offer Proof of Concept (PoC) and Minimum Viable Product (MVP) services to help you
        validate your ideas and bring them to market quickly and efficiently. Our team of experienced developers
        specializes in creating PoCs and MVPs for blockchain and web3 applications. We work closely with you to
        understand your vision and develop a solution that meets your specific needs. Our PoC and MVP services include
        research, design, development, testing, and deployment of a product with just enough features to create a
        working prototype based on the initial concept that can provide a solid foundation for developing a full-fledged
        product. We use the latest blockchain and web3 technologies to build a functional prototype that demonstrates
        the core features of your idea. With our PoC and MVP services, you can validate your concept and test its
        feasibility in a real-world environment, enabling rapid market entry, crucial for gaining first mover advantage,
        especially for startup. Our goal is to help you minimize risk and maximize your chances of success, whether you
        are looking to raise funds or launch a new product. We are committed to delivering high-quality solutions that
        exceed your expectations.`
      },
      extendedTeam: {
        title: 'Extended Team',
        subTitle: 'Internalizing Hi-Tech intellectual capital',
        titleBody:
          'We are entrepreneurs first and we work to be an innovative entrepreneurship hub for the Web3 market.',
        roleTitle: 'Our Role',
        roleBody: `We understand the importance of having a talented and dedicated team to bring your blockchain and Web3 projects
        to life. That's why we offer an extended team service, where we provide skilled developers to work alongside
        your existing team or handle entire projects from start to finish. Our team has extensive experience in
        developing blockchain and Web3 applications, and we take pride in staying up-to-date with the latest industry
        developments. We offer flexible engagement models to meet your unique needs and work with you every step of the
        way to ensure your project is a success. In this way, you can scale your team quickly and efficiently without
        the hassle of recruiting and onboarding, taking the advantages of fully remote working.
        We provide the best talent to achieve your goals.`,
        roadmapTitle: 'Operative Roadmap',
        firstStep: {
          title: 'Team Needs and Project Requirements Analysis',
          body: `We work with you to identify your team needs and project requirements. We'll discuss your business
          objectives and determine the skills and experience needed for your project. We'll also help you define
          project timelines, budgets, and milestones to ensure that your extended team is aligned with your project
          goals.`
        },
        secondStep: {
          title: 'Candidate Selection and Screening',
          body: `Once we have identified your team needs, our recruitment team will work to identify and screen potential
          candidates. We'll look for candidates with the right skills, experience, and cultural fit for your project.
          Our selection process is rigorous, and we'll only present candidates that meet our high standards.`
        },
        thirdStep: {
          title: 'Team Integration',
          body: `After selecting the candidates, our team will work to integrate them into your existing team. We'll ensure
          that your extended team has the necessary tools, processes, and documentation to work effectively with your
          team. We'll also provide ongoing support and coaching to help your extended team members succeed.`
        }
      },
      audit: {
        title: 'Security Audit',
        subTitle: `Code is Law`,
        titleBody: `We provide to your contracts high security standards. Smart Contracts are used in a protocol that by nature is
        immutable governed by a distributed consensus, so no third party will be able to fix things.
        This is why no errors are allowed!`,
        roleTitle: 'Our Role',
        roleBody: `Smart contracts are the backbone of many Web3 applications. That's why we offer smart contract security audit
        services to help you identify and mitigate potential vulnerabilities before they can be exploited. Our team of
        experienced blockchain developers and security experts specializes in performing smart contract security audits.
        We use a variety of industry-standard tools and techniques to thoroughly analyze your smart contracts and
        identify any potential security risks. Our smart contract security audit services include a detailed report
        outlining any identified vulnerabilities and recommendations for how to address them. We work closely with you
        to ensure that your smart contracts meet the highest standards of security and are fully compliant with industry
        regulations. With our smart contract security audit services, you can trust that your blockchain and web3
        applications are secure and protected against potential threats. Our goal is to help you build secure and
        trustworthy applications that you and your users can rely on.`
      },
      customers: {
        title: 'Customers',
        subTitle: 'Partners in Innovation and Progress',
        bio: `The disruptive innovation and revolution brought by blockchain and Web3 are still in a phase of discovery and
        opportunities to be explored. That is why we, like our partners, are perfect examples of pioneers of the
        avant-garde.`,
        findOut: 'Find out more about our Customers',
        propeller: {
          title: 'Propeller Heads',
          firstBody: `Propeller Heads is a decentralized infrastructure that optimizes trades for wallets, dapps, 
          and users by ensuring the best prices through Smart Routing and Private Submission mechanisms. By leveraging Smart Routing,
          Propeller accesses quotes and identifies optimal swap routes via the Solver API for any trade.
          Utilizing Private Submission ensures that all trades are fully protected using the Private RPC protocol, providing a secure environment for builders. Additionally,
          Propeller Heads supports protocols by developing bots that maintain protocol health and protect against Manipulation, Extraction, and Front-running (MEV) risks.
          `,
          secondBody: `Our collaboration with the Propeller Heads team began with a task on their leaderboard and has since evolved
           into a long-term partnership spanning various levels of their technological stack.
           We have contributed to Smart Contracts integration with new protocols, substream development, leaderboard enhancements, and frontend and backend improvements.
          Our relationship with Propeller Heads has been extremely positive, and we look forward to continuing our successful collaboration.`
        },
        bip: {
          title: 'Bip Consulting',
          firstBody: `Bip Consulting is a strategic consulting firm that provides consultancy and support services for planning
          and implementing business projects on an international level. The company focuses on collaborating with
          clients to identify their business needs and objectives, and then providing customized solutions that
          enable them to achieve their goals. Some of the services offered by Bip Consulting include strategic
          consultancy, change management, training and organizational development, project management, and much
          more.`,
          secondBody: `Bip Consulting is a very dynamic, innovation-oriented company, which is why that it started to put its
          attention in blockchain technology and web3. To rapidly grow their knowledge and skills in the sector, a
          period of collaboration was established between Bip and Shadowy Creators. During this period, the services
          we offered were focused on supporting the product developed in the Web3 space, with particular attention
          to the audit phase of smart contracts in different ecosystems such as Ethereum, or more generally the EVM
          ecosystem, and Algorand.`
        },
        decash: {
          title: 'DeCash',
          firstBody: `DeCash is a Crypto-Financial Services Company, established in 2020, that has earned its reputation as an
          industry leader and a pioneering force behind the development of the Stablecoin. Their goal is to enable
          fast, secure, and efficient transactions of the worlds most traded fiat currencies, through a transparent
          and accessible protocol available to everyone, the blockchain. Their stable token, backed 1:1 by assets
          held in secure reserve accounts, are supported by an ecosystem which contains liquidity providers, payment
          platforms, exchanges as well as asset reserves.`,
          secondBody: `Our collaboration with Decash is born to implement their ideas, to create a real ecosystem around Decash
          tokens, which are not just multi-currency stablecoins, but also multi-chain stablecoins. Together, we are
          making it possible to use the security and transparency of the blockchain in traditional finance in a
          simple and accessible way.`
        },
        pv01: {
          title: 'PV01',
          firstBody: `PV01 is a proven group of business builders who have the traditional capital markets expertise combined
          with institutional-grade digital asset experience required to develop a proper debt capital market. Their
          mission is to build and operate a debt capital market on which a healthy digital asset ecosystem can
          thrive. The PV01 team was handpicked from TradFi and DeFi and is ready to build the rails for the future
          of the debt market`,
          secondBody: `The role of Shadowy Creators is to develop the underlying technology to make on-chain Treasury bonds real.
          Platform streamlines old-school due diligence and underwriting for the primary market, with a secondary
          market made seamless by real-time transfers and state-of-the-art dealing systems, using blockchain for
          what it’s meant to be used: provable digital ownership and transferability.`
        },
        labtrace: {
          title: 'LabTrace',
          firstBody: `LabTrace is at the forefront of revolutionizing the integrity of scientific data. By leveraging a blockchain-based system, they've established a secure, GDPR-compliant, and immutable method for notarizing digital data. Addressing the challenge of reproducibility in scientific research, LabTrace ensures the preservation of the chain of evidence, a cornerstone of scientific validation. Particularly in fields like medicine, where consistent innovation is crucial, LabTrace's solution provides a trustable system for certifying transparent and verifiable scientific processes.`,
          secondBody: `Shadowy Creators and LabTrace embarked on a journey to revolutionize the underlying technology for on-chain scientific verification. Tasked with understanding an intricate codebase, innovating with new features, and establishing a Docker-based development environment, our focus was to fortify LabTrace's already robust system.`
        }
      },
      contact: {
        title: 'Contact us',
        subTitle: `If you’re considering any of the Shadowy solutions 
        or just want more informations, simply fill out the
        form and we’ll be in touch.`,
        form: {
          name: 'Name',
          company: 'Company',
          phone: 'Phone',
          lookingFor: 'Looking for',
          options: {
            select: 'Select one',
            consulting: 'Consulting',
            development: 'Project Development',
            poc: 'PoC & MPV development',
            audit: 'Security Audit',
            extended: 'Extended Team'
          },
          description: 'Short Description',
          policy: `I have read and agree to Shadowy's Privacy Policy.`,
          sendButton: 'send'
        }
      }
    }
  },

  //////////////////////////////////////////////////////  //////////////////////////////////////////////////////  //////////////////////////////////////////////////////

  it: {
    message: {
      navbar: {
        home: 'Home',
        about: 'Chi siamo',
        services: 'Servizi',
        servicesOptions: {
          all: 'Tutti i Servizi',
          consulting: 'Consulenza',
          development: 'Sviluppo Progetto',
          poc: 'Sviluppo PoC & MPV',
          audit: 'Audit Sicurezza',
          extended: 'Espansione Team'
        },
        clients: 'Clienti',
        contact: 'Contattaci'
      },
      reviews: {
        title: 'Cosa dicono i nostri clienti?',
        DeCash: {
          description:
            'DeCash è la prima Stablecoin indipendente e facile da usare che combina una gestione trasparente delle riserve con i vantaggi delle valute fiat più scambiate al mondo',
          review:
            '"La collaborazione con Shadowy Creators è stata un momento cruciale per Decash. Mentre immaginavamo una stablecoin unica e facile da usare, loro ci hanno fornito l\'abilità tecnica per trasformare questa visione in realtà. Il loro impegno per l\'eccellenza, unito alla puntualità e alla profonda conoscenza della blockchain, ha garantito un prodotto che si distingue sul mercato. Non avremmo potuto chiedere un partner migliore per il nostro viaggio".',
          team: '— Decash Team'
        },
        PV01: {
          description:
            "PV01 combina l'esperienza dei capitali tradizionali con il know-how degli asset digitali per sviluppare un fiorente mercato dei capitali di debito. Selezionati da TradFi e DeFi, sono pronti a plasmare il futuro dei mercati del debito",
          review:
            '"La collaborazione con Shadowy Creators è stata trasformativa per PV01. Mentre cercavamo di fondere il know-how del capitale tradizionale con il regno digitale, loro ci hanno offerto un\'acutezza tecnica senza pari. La loro dedizione alla perfezione, la puntualità delle consegne e la profonda conoscenza delle dinamiche della blockchain ci hanno permesso di creare una piattaforma leader di mercato. Nella nostra missione di rivoluzionare i mercati del debito, si sono dimostrati un alleato prezioso."',
          team: '— PV01 Team'
        }
      },
      exploreCustomers: 'Esplora i nostri clienti >',
      chainsWeLike: 'Blockchain su cui operiamo',
      ctaBoxMain: {
        title: 'Facciamo una chiacchierata e scopriamo come!',
        subTitle:
          'Scopri come Shadowy Creators può elevare la tua visione attraverso la blockchain - contattaci, senza impegno.'
      },
      ctaBoxTelegram: {
        title: 'vuoi una risposta velocemente?',
        subTitle: 'Mandaci un messaggio direttamente su Telegram!',
        shootMessage: 'O mandaci un messaggio ',
        directlyTelegram: 'direttamente su Telegram!'
      },
      contactBox: {
        title: `Costruiamo qualcosa di unico!`,
        button: `Contattaci`
      },
      trustedBy: 'Si affidano a noi',
      button: {
        getInTouch: 'Contattaci',
        contactUs: 'Contattaci',
        findMore: 'Scopri di più',
        telegram: 'Telegram'
      },
      whyShadowy: {
        whyShadowy1: {
          title: 'Perché Shadowy Creators',
          body: `Scopri un mondo in cui le tecnologie blockchain e dei registri distribuiti stanno trasformando aziende e settori. 
          Noi di Shadowy Creators, sfruttiamo il potere di queste soluzioni all'avanguardia per aiutare la tua azienda a prosperare nell'attuale panorama digitale in rapida evoluzione. 
          Ti aiutiamo a capire i requisiti, fissiamo le scadenze, consegniamo il prodotto. 
          È davvero così semplice.`
        }
      },
      home: {
        title: 'Creiamo soluzioni Blockchain su misura',
        subtitle:
          'Più che semplici sviluppatori. Siamo il tuo partner Blockchain, pronti ad affiancarti per raggiungere nuovi traguardi.'
      },
      aboutUs: {
        title: 'Attenzione al Futuro',
        subtitle: `Lavoriamo sui progetti Web3, crypto e SW più avanzati.
        Lavoriamo su idee innovative e supportiamo persone di talento nel lanciare i loro progetti`,
        firstBlockTitle: 'Una Software House Decentralizzata',
        firstBlockBody: `Stabiliamo le nostre priorità in base ai migliori rendimenti di ogni Shadowy Coder.
        La libertà ed il possesso sulla gestione dei compiti, del tempo e delle attività personali sono l'incentivo perfetto per
        risultati di qualità.
        Ogni Shadowy Coder sa cosa fare e come farlo .
        100% di Up-Time distribuito`,
        secondBlockTitle: 'La Catena del miglioramento',
        secondBlockBody: `Un flusso continuo di nuove competenze e conoscenze ci permette di migliorare giorno dopo giorno.
        Questo è l'unico modo per poter operare ed essere competitivi in questo settore tecnologico così dinamico e dirompente.`
      },
      services: {
        title: 'Scopri i nostri Servizi',
        subtitle: 'Soluzioni Blockchain Hi-Tech ',
        titleBody: 'Metteremo a disposizione il nostro know-how tecnico per le tue idee ed i tuoi progetti.',
        titleCards: 'Possiamo aiutarti a raggiungere un nuovo livello!',
        cards: {
          consulting: {
            title: `Consulenza\nBlockchain`,
            body: 'Ti aiuteremo a comprendere la tecnologia Blockchain in base alle tue idee ed ai tuoi progetti'
          },
          development: {
            title: 'Sviluppo di\nProgetti',
            body: 'Noi ti forniremo tutte l nostre conoscenze e competenze.\nCTO-as-a-Service'
          },
          audit: {
            title: 'Audit di\nSicurezza',
            body: `Valutazione completa della sicurezza dei tuoi smart contract e del codice per identificare le vulnerabilità,  consigliare ed implementare le migliori pratiche di sicurezza.`
          },
          poc: {
            title: 'PoC & MVP',
            body: 'Ideiamo una potenziale soluzione (PoC) e, se fattibile, sviluppiamo il prototipo al meglio (MVP).'
          },
          team: {
            title: 'Estensione\ndel Team',
            body: 'I nostri sviluppatori altamente qualificati si uniranno al tuo team!'
          }
        }
      },
      consulting: {
        title: 'Consulenza Blockchain',
        subTitle: 'Trasformando le tue idee in realtà',
        titleBody:
          'Possiamo aiutarti a trasformare la tua idea di alto livello in qualcosa capace di supportare lo sviluppo futuro.',
        roleTitle: 'Il nostro ruolo',
        roleBody: `La tecnologia Blockchain e le piattaforme Web3 stanno trasformando il modo in cui le aziende operano nell'era digitale. Noi
        offriamo servizi di consulenza specialistica per aiutarti a sfruttare le potenzialità di queste tecnologie all'avanguardia. Il nostro team di
        esperti in blockchain e di sviluppatori Web3 lavorerà con te per comprendere le tue esigenze aziendali e progettare
        soluzioni personalizzate in grado di aiutarti a semplificare le operazioni, migliorare la sicurezza e sbloccare nuovi flussi di entrate e nuovi business.
        Conosciamo e lavoriamo con tutti i tipi di implementazione blockchain e Web3.
        Abbiamo l'esperienza e le conoscenze necessarie per guidarti in ogni fase del processo.`,
        roadmapTitle: 'Mappa Operativa',
        firstStep: {
          title: `Valutazione del'idea`,
          body: `Offriamo un servizio di valutazione iniziale per valutare la tua idea. Siamo consapevoli dell'importanza di una base
          solida e vogliamo assicurarci che la tua idea sia fattibile, tecnicamente valida e che soddisfi i tuoi obiettivi di business.
          Il nostro team eseguirà un'analisi approfondita della tua idea e ti fornirà preziosi feedback e suggerimenti per aiutarti a perfezionare la tua idea in questo settore.`
        },
        secondStep: {
          title: 'Fase di analisi/progettazione',
          body: `La nostra fase di analisi/progettazione è necessaria per aiutarti a definire i requisiti della tua soluzione. Il nostro team
          lavorerà con te per identificare le caratteristiche, le funzionalità e l'architettura chiave necessarie per dare vita alla tua soluzione blockchain/Web3.
          Utilizziamo le tecnologie e i framework più all'avanguardia per sviluppare un'architettura robusta e scalabile con una roadmap di sviluppo di alto livello capace di
          soddisfare le tue esigenze aziendali attuali e future.`
        },
        thirdStep: {
          title: 'Design Strutturale',
          body: `Una volta completata la fase di analisi/progettazione, passiamo alla creazione del Design che meglio si adatta alla tua soluzione blockchain.
          Il nostro team di esperti designer UX e UI lavorerà con te per 
          creare wireframe visivamente accattivanti ed intuitivi. Il nostro obiettivo è trovare un equilibrio tra
          estetica e usabilità, assicurandoti che la tua soluzione blockchain/Web3 non sarà solo bella da vedere ma anche
          facile da usare.`
        }
      },
      development: {
        title: 'Sviluppo di\nProgetto',
        subTitle: `Uno sviluppo di progetto blockchain dall'inizio alla fine`,
        titleBody: `Determiniamo il modo migliore per introdurre la blockchain e il Web3 nelle tue operazioni o nei tuoi servizi, oltre a 
        determinare il corretto approccio tecnico allo sviluppo ed all'implementazione.`,
        roleTitle: 'Il nostro ruolo',
        roleBody: `Siamo esperti nello sviluppo di progetti blockchain e web3. Siamo consapevoli che queste tecnologie possono essere complesse,
        per questo offriamo servizi di sviluppo di progetti end-to-end per dare vita alle tue idee. Il nostro team di sviluppatori
        ha una profonda conoscenza delle tecnologie blockchain e web3 e si impegna a fornire soluzioni di alta qualità che soddisfino ogni tua specifica esigenza.
        Dall'ideazione al lancio, lavoriamo con te in ogni fase per garantire il successo del vostro progetto. 
        Offriamo una gamma di servizi di sviluppo, sviluppo di smart contract, sviluppo di applicazioni decentralizzate (dApp) e sviluppo di token (fungibili e non fungibili).
        Sia che tu abbia bisogno di una soluzione personalizzata o che tu voglia costruire su una piattaforma blockchain esistente, abbiamo l'esperienza per
        raggiungere i risultati che desideri. Con i nostri servizi di sviluppo di progetti blockchain e web3, puoi contare sul fatto che saremo noi a
        gestire tutti gli aspetti tecnici del tuo progetto, tra cui la progettazione (UI e UX), lo sviluppo, i test e la distribuzione. Il nostro
        obiettivo è quello di superare le tue aspettative e aiutarti a sfruttare il pieno potenziale di queste entusiasmanti tecnologie.`,
        roadmapTitle: 'Mappa Operativa',
        firstStep: {
          title: 'Analisi del Business e Specifiche dei Requisiti',
          body: `Iniziamo con un'analisi completa dei vostri requisiti aziendali per assicurarci di comprendere appieno le tue esigenze ed i tuoi obiettivi.
          Il nostro team lavorerà a stretto contatto con te per identificare le caratteristiche e le funzionalità chiave
          che la tua soluzione blockchain richiede. Prenderemo in considerazione anche fattori quali la scalabilità, la sicurezza e la
          conformità alle normative per garantire che la soluzione soddisfi tutti i requisiti aziendali.`
        },
        secondStep: {
          title: 'Specifiche Tecniche',
          body: `Una volta che abbiamo una chiara comprensione dei tuoi requisiti, cominceremo a sviluppare l'architettura tecnica
          per la tua soluzione blockchain. Il nostro team di sviluppatori esperti creerà un'architettura robusta e scalabile che soddisferà le tue
          esigenze aziendali. Inoltre, forniremo una documentazione tecnica dettagliata per assicurare che
          che il tuo team abbia una chiara comprensione dell'architettura della soluzione.`
        },
        thirdStep: {
          title: 'UI/UX Design',
          body: `Siamo consapevoli dell'importanza dell'esperienza utente per il successo di una soluzione Web3. Il nostro team di
          designer lavorerà con voi per creare un'interfaccia utente intuitiva e visivamente accattivante che soddisfi le tue esigenze aziendali.
          Creeremo wireframe e prototipi per garantire che l'esperienza dell'utente sia ottimizzata prima di passare alla fase di sviluppo.`
        },
        fourthStep: {
          title: 'Sviluppo Smart Contract',
          body: `I contratti intelligenti sono una componente fondamentale di qualsiasi soluzione blockchain e il nostro team ha una vasta esperienza 
          nello sviluppo di contratti intelligenti. Creeremo contratti intelligenti sicuri, efficienti e in grado di soddisfare le
          specifiche esigenze aziendali. Il nostro team eseguirà anche test approfonditi per garantire che gli Smart Contract
          siano privi di bug e soddisfino tutti i requisiti, quelli di sicurezza per primi.`
        },
        fifthStep: {
          title: 'Sviluppo Frontend e Backend',
          body: `Una volta sviluppati i contratti, passeremo allo sviluppo del frontend e del backend. Il nostro team di
          sviluppatori esperti creerà un frontend reattivo e scalabile che offra un'esperienza utente eccezionale.
          Svilupperemo anche un backend robusto che gestisca l'archiviazione dei dati, l'integrazione con sistemi esterni e altre funzioni essenziali.`
        },
        sixthStep: {
          title: `Configurazione e manutenzione dell'infrastruttura`,
          body: `Una volta terminato lo sviluppo, creeremo l'infrastruttura necessaria per implementare il progetto.
          Ci assicureremo che l'infrastruttura sia sicura, scalabile e ottimizzata per le prestazioni. Inoltre, forniremo
          manutenzione e assistenza continua per garantire che tutto rimanga stabile e sicuro.`
        }
      },
      poc_MVP: {
        subTitle: 'Prototipazione prima del successo',
        titleBody: `Forniamo un modo rapido ed economico per valutare un'idea in un ambiente sicuro con un solido PoC.
        Entra rapidamente nel mercato con un MVP ben progettato.`,
        roleTitle: 'Il nostro Ruolo',
        roleBody: ` La prototipazione di un sistema o di una soluzione è uno dei più importanti servizi blockchain aziendali che 
        un'azienda professionale possa offrire. La costruzione di applicazioni blockchain e web3 può essere un processo complesso e impegnativo,
        infatti, proprio per questo la prototipazione non è solo cruciale per testare la fattibilità di un concetto,
        ma può anche svolgere un ruolo cruciale per il successo di un progetto, gettando una solida base per sostenere l'ulteriore sviluppo.
        Ecco perché offriamo servizi di Proof of Concept (PoC) e Minimum Viable Product (MVP) per aiutarti a
        convalidare le tue idee e portarle sul mercato in modo rapido ed efficiente. Il nostro team di sviluppatori esperti
        è specializzato nella creazione di PoC ed MVP per applicazioni blockchain e web3. Lavoriamo a stretto contatto con te per
        comprendere la tua visione e sviluppare una soluzione che soddisfi le tue esigenze specifiche. I nostri servizi di PoC e MVP includono
        ricerca, progettazione, sviluppo, test e distribuzione di un prodotto con caratteristiche sufficienti a creare un prototipo funzionante basato sul concetto iniziale.
        in grado di fornire una solida base per lo sviluppo di un prodotto completo a tutti gli effetti. 
        Utilizziamo le più aggiornate tecnologie blockchain e web3 per costruire un prototipo funzionante che dimostri
        le caratteristiche principali della tua idea. Con i nostri servizi PoC e MVP, puoi convalidare il tuo concetto e testarne la fattibilità in un ambiente reale.
        consentendoti un rapido ingresso nel mercato, cruciale per ottenere il vantaggio del first mover, fondamentale soprattutto per le startup.
        Il nostro obiettivo è quello di aiutarti a ridurre al minimo i rischi e a massimizzare le tue possibilità di successo, sia che tu stia cercando di raccogliere fondi o di lanciare una nuova impresa o prootto.
        Ci impegniamo a fornire soluzioni di alta qualità che superino le tue aspettative.`
      },
      extendedTeam: {
        title: 'Estensione del Team',
        subTitle: 'Internalizzazione del capitale intellettuale Hi-Tech',
        titleBody:
          'Siamo prima di tutto imprenditori e lavoriamo per essere un hub imprenditoriale innovativo per il mercato Web3.',
        roleTitle: 'Il nostro rRuolo',
        roleBody: `Comprendiamo l'importanza di avere un team talentuoso e dedicato per dare vita ai tuoi progetti blockchain e Web3.
        Per questo motivo offriamo un servizio di team esteso, in cui mettiamo a disposizione sviluppatori qualificati per affiancare
        un team esistente o per crearne uno in grado di gestire interi progetti dall'inizio alla fine. Il nostro team ha una vasta esperienza nello
        nello sviluppo di applicazioni blockchain e Web3 e siamo orgogliosi di rimanere aggiornati sugli ultimi sviluppi del settore.
        Offriamo perciò modelli di servizio flessibili per soddisfare le tue esigenze specifiche e lavoriamo con te in ogni fase del processo 
        per garantire il successo del tuo progetto. In questo modo, puoi far crescere il tuo team in modo rapido ed efficiente 
        senza i problemi di reclutamento e di onboarding, sfruttando i vantaggi di un lavoro completamente da remoto.
        Forniamo i migliori talenti per farti raggiungere i tuoi obiettivi.`,
        roadmapTitle: 'Mappa Operativa',
        firstStep: {
          title: 'Analisi delle esigenze del team e dei requisiti del progetto',
          body: `Lavoriamo con te per identificare le esigenze del tuo team e i requisiti del progetto. Discuteremo i vostri obiettivi aziendali e
          determineremo le competenze e l'esperienza necessaria per il vostro progetto. Vi aiuteremo inoltre a definire
          il progetto, il budget e le tappe fondamentali per garantire che il team esteso sia allineato con gli obiettivi del progetto.`
        },
        secondStep: {
          title: 'Selezione e screening dei candidati',
          body: `Una volta identificate le esigenze del vostro team, il nostro team di reclutamento lavorerà per identificare e vagliare i potenziali
          candidati. Cercheremo candidati con le competenze, l'esperienza e la cultura giusta per il vostro progetto.
          Il nostro processo di selezione è rigoroso e presenteremo solo candidati che soddisfino i nostri elevati standard.`
        },
        thirdStep: {
          title: 'Integrazione del team',
          body: `Dopo aver selezionato i candidati, il nostro team lavorerà per integrarli nel vostro team esistente. Ci assicureremo
          che il team esteso disponga degli strumenti, dei processi e della documentazione necessari per lavorare efficacemente con il vostro team.
          Inoltre, forniremo assistenza e coaching continui per aiutare i membri di tutto il team ad avere successo.`
        }
      },
      audit: {
        title: 'Audit di Sicurezza',
        subTitle: `Il Codice è legge`,
        titleBody: `Forniamo ai vostri contratti elevati standard di sicurezza. I contratti intelligenti sono utilizzati in un protocollo che per natura è
        immutabile governato da un consenso distribuito, quindi nessuna terza parte sarà in grado di correggere o mediare eventuali anomalie.
        Per questo motivo non sono ammessi errori!`,
        roleTitle: 'Il nostro Ruolo',
        roleBody: `Gli Smart Contract sono la spina dorsale di molte applicazioni Web3. Per questo motivo offriamo servizi di verifica della sicurezza dei contratti 
        per aiutarti ad identificare e mitigare le potenziali vulnerabilità prima che possano essere sfruttate. Il nostro team di
        esperti di blockchain e di sicurezza dei vari protocolli è specializzato nell'esecuzione di audit  di sicurezza con elevata accuratezza degli smart contract.
        Utilizziamo una serie di strumenti e tecniche standard del settore per analizzare a fondo i tuoi contratti ed identificare i potenziali rischi per la sicurezza.
        I nostri servizi di verifica della sicurezza dei contratti intelligenti includono un rapporto dettagliato
        che delinea tutte le vulnerabilità identificate e le raccomandazioni su come affrontarle. Lavoriamo a stretto contatto con te
        per garantire che i tuoi contratti intelligenti soddisfino i più elevati standard di sicurezza e siano pienamente conformi alle normative del settore.
        settore. Con i nostri servizi di verifica della sicurezza degli smart contract, potrai essere certo che le tue applicazioni blockchain e web3
        siano sicure e protette da potenziali minacce. Il nostro obiettivo è aiutarvi a costruire applicazioni sicure e
        ed affidabili su cui te ed i tuoi utenti potrete fare affidamento.`
      },
      customers: {
        title: 'Clienti',
        subTitle: `Partner dell'Innovazione e del Progresso`,
        bio: `L'innovazione e la dirompente rivoluzione portate dalla tecnologia blockchain sono ancora in una fase di scoperta e di opportunità da esplorare.
        Ecco perché noi, come i nostri partner, siamo perfetti esempi di pionieri dell'avanguardia.`,
        findOut: 'Sopri di più sui nostri Partner',
        propeller: {
          title: 'Propeller Heads',
          firstBody: `Propeller Heads è un'infrastruttura decentralizzata che ottimizza gli scambi per portafogli, dapp e utenti 
          garantendo i migliori prezzi attraverso meccanismi di Smart Routing e Private Submission.
          Sfruttando Smart Routing, Propeller accede a preventivi e identifica percorsi di scambio ottimali tramite il Solver API per qualsiasi operazione.
          Utilizzando Private Submission, si assicura che tutti gli scambi siano completamente protetti utilizzando il protocollo Private RPC,
           offrendo un ambiente sicuro per gli sviluppatori. Inoltre,
          Propeller supporta i protocolli sviluppando bot che mantengono la salute del protocollo e proteggono dai rischi di Manipulation,
          Extraction e Front-running (MEV).`,
          secondBody: `La nostra collaborazione con il team di Propeller Heads è iniziata con un task sulla loro leaderboard
           e da allora si è evoluta in una partnership a lungo termine che coinvolge vari livelli della loro infrastruttura tecnologica.
           Abbiamo contribuito all'integrazione di protocolli DeFi attraverso Smart Contracts,
           allo sviluppo dei substream, al potenziamento della leaderboard, nonché a miglioramenti di frontend e backend.
          Il nostro rapporto con Propeller Heads è estremamente positivo e la nostra collaborazione di successo continua.`
        },
        bip: {
          title: 'Bip Consulting',
          firstBody: `Bip Consulting è una società di consulenza strategica che fornisce servizi di consulenza e supporto per la pianificazione
          e l'implementazione di progetti aziendali a livello internazionale. L'azienda si concentra sulla collaborazione con
          clienti per identificare le loro esigenze e i loro obiettivi di business, per poi fornire soluzioni personalizzate che
          che consentano loro di raggiungere gli obiettivi prefissati. Tra i servizi offerti da Bip Consulting vi sono la
          consulenza strategica, gestione del cambiamento, formazione e sviluppo organizzativo, project management e molto altro ancora.`,
          secondBody: `Bip Consulting essendo un'azienda molto dinamica ed orientata all'innovazione, ha iniziato a porre la propria 
          attenzione alla tecnologia blockchain e al web3. Per accrescere rapidamente le proprie conoscenze e competenze nel settore, è stato avviato un 
          periodo di collaborazione tra Bip e Shadowy Creators. Durante questo periodo, i servizi
          offerti si sono concentrati sul supporto del prodotto sviluppato nell'ecosistema Web3, con particolare attenzione
          alla fase di audit degli smart contract in diversi protocolli, come Ethereum, o più in generale l'ecosistema EVM, ed Algorand.`
        },
        decash: {
          title: 'DeCash',
          firstBody: `DeCash è una società di servizi cripto-finanziari, fondata nel 2020, che si è guadagnata la reputazione di 
          leader del settore e forza pionieristica dietro lo sviluppo delle Stablecoin. Il loro obiettivo è quello di consentire
          transazioni rapide, sicure ed efficienti delle valute fiat più scambiate al mondo, attraverso un protocollo trasparente 
          ed accessibile a tutti: la blockchain. I loro token stabili, sostenuti 1:1 dalla detenzione in conti di riserva sicuri, 
          sono supportati da un ecosistema che comprende fornitori di liquidità, piattaforme di pagamento, scambi e riserve di assets.`,
          secondBody: `La nostra collaborazione con Decash è nata per implementare le loro idee, al fine di creare un vero e proprio ecosistema intorno ai token Decash
          che non sono solo delle stablecoin multivaluta, ma anche stablecoin multi-chain. Insieme, stiamo
          rendendo possibile l'utilizzo della sicurezza e della trasparenza della blockchain nella finanza tradizionale in modo semplice ed accessibile.`
        },
        pv01: {
          title: 'PV01',
          firstBody: `PV01 è un gruppo collaudato di imprenditori che possiedono la tradizionale esperienza nei mercati dei capitali combinata 
          con un'esperienza di livello istituzionale nel settore degli asset digitali, necessaria per sviluppare un vero e proprio mercato dei capitali di debito. La loro
          missione è quella di costruire e gestire un mercato dei capitali di debito su cui possa prosperare un sano ecosistema di asset digitali.
          Il team di PV01 è stato selezionato minuziosamente dal mondo della TradFi e della DeFi ed è pronto a costruire i binari per il futuro del mercato del debito.`,
          secondBody: `Il ruolo di Shadowy Creators è quello di sviluppare la tecnologia di base per rendere reali i titoli del Tesoro su blockchain.
          La piattaforma semplifica la due diligence e l'underwriting della scuola tradizionale per il mercato primario, con un mercato secondario
          reso fluido da trasferimenti in tempo reale e da sistemi di negoziazione all'avanguardia, utilizzando la blockchain per
          per quello che è il suo principale scopo: proprietà digitale dimostrabile e trasferibilità.`
        },
        labtrace: {
          title: 'LabTrace',
          firstBody: `LabTrace è all'avanguardia nel rivoluzionare l'integrità dei dati scientifici. Sfruttando un sistema basato su blockchain, ha creato un metodo sicuro, conforme al GDPR e immutabile per autenticare i dati digitali. Affrontando la sfida della riproducibilità nella ricerca scientifica, LabTrace garantisce la conservazione della catena di prove, una pietra miliare della validazione scientifica. Soprattutto in campi come la medicina, dove l'innovazione è fondamentale, la soluzione LabTrace fornisce un sistema affidabile per certificare processi scientifici trasparenti e verificabili.`,
          secondBody: `Shadowy Creators e LabTrace hanno intrapreso un viaggio per rivoluzionare la tecnologia di base per la verifica scientifica on-chain. Con il compito di comprendere un codice intricato, innovare con nuove funzionalità e creare un ambiente di sviluppo basato su Docker, il nostro obiettivo era quello di rafforzare il già solido sistema di LabTrace.`
        }
      },
      contact: {
        title: 'Contattaci',
        subTitle: `Se stai prendendo in considerazione una qualsiasi delle soluzioni offerte 
        da Shadowy Creators o vuoi semplicemente avere maggiori informazioni, compila il form
        e ci metteremo in contatto con te.`,
        form: {
          name: 'Nome',
          company: 'Azienda',
          phone: 'Telefono',
          lookingFor: 'Servizio richiesto',
          options: {
            select: 'Seleziona',
            consulting: 'Consulenza',
            development: 'Sviluppo Progetto',
            poc: 'PoC & MPV',
            audit: 'Audit di Sicurezza',
            extended: 'Estensione Team'
          },
          description: 'Breve Descrizione',
          policy: `Ho letto e accetto l'Informativa sulla privacy di Shadowy.`,
          sendButton: 'Invia'
        }
      }
    }
  }
}
