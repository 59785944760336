import axios from 'axios'
import type { ClientInfoInterface } from '../types/clientInfoInterface'
import { validatedENV } from '../zodValidation'

export function sendTelegramMessage(clientInfo: ClientInfoInterface) {
  const telegramBotToken = validatedENV.VITE_BOT_TOKEN
  const telegramGroupId = validatedENV.VITE_GROUP_ID
  const url = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`
  const text = `WEBSITE CONTACT NOTIFICATION:
    Name: ${clientInfo.name}
    Company: ${clientInfo.company}
    Phone: ${clientInfo.phone || 'N/A'}
    Email: ${clientInfo.email}
    Service: ${clientInfo.service}
    Description: ${clientInfo.description}`
  const data = { chat_id: telegramGroupId, text }
  return axios.post(url, data)
}
