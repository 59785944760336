<script setup lang="ts">
import { ref } from 'vue';
import LanguageCheckbox from './LanguageCheckbox.vue';

const isMenuOpen = ref(false)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const closeMenu = () => {
  isMenuOpen.value = false
}
const openMenu = () => {
  isMenuOpen.value = true
}
</script>
<template>
  <div class="navbar p-2 md:p-10 h-32">
    <div class="navbar-start">
      <div class="dropdown">
        <label tabindex="0" class="btn btn-ghost sm:hidden" @click="toggleMenu()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 md:h-10 md:w-10"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
          </svg>
        </label>

        <ul
          v-show="isMenuOpen"
          tabindex="0"
          class="menu menu-compact w-auto md:w-52 dropdown-content mt-3 p-2 z-50 shadow bg-base-300 rounded-box"
        >
          <li>
            <router-link to="/" @click="toggleMenu()">{{ $t('message.navbar.home') }}</router-link>
          </li>
          <li>
            <router-link to="/about" @click="toggleMenu()">{{ $t('message.navbar.about') }}</router-link>
          </li>

          <li tabindex="0">
            <a class="justify-between">
              {{ $t('message.navbar.services') }}
              <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
              </svg>
            </a>
            <ul class="p-2 shadow bg-base-200">
              <li>
                <router-link to="/services" @click="toggleMenu()">{{
                  $t('message.navbar.servicesOptions.all')
                }}</router-link>
              </li>
              <li>
                <router-link to="/services/consulting" @click="toggleMenu()">{{
                  $t('message.navbar.servicesOptions.consulting')
                }}</router-link>
              </li>
              <li>
                <router-link to="/services/projectDevelopment" @click="toggleMenu()">{{
                  $t('message.navbar.servicesOptions.development')
                }}</router-link>
              </li>
              <li>
                <router-link to="/services/PoC&MVP" @click="toggleMenu()">{{
                  $t('message.navbar.servicesOptions.poc')
                }}</router-link>
              </li>
              <li>
                <router-link to="/services/audit" @click="toggleMenu()">{{
                  $t('message.navbar.servicesOptions.audit')
                }}</router-link>
              </li>
              <li>
                <router-link to="/services/extendedTeam'" @click="toggleMenu()">{{
                  $t('message.navbar.servicesOptions.extended')
                }}</router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/clients" @click="toggleMenu()">{{ $t('message.navbar.clients') }}</router-link>
          </li>
          <li>
            <router-link to="/contact" @click="toggleMenu()">{{ $t('message.navbar.contact') }}</router-link>
          </li>
        </ul>
      </div>
      <img src="/src/assets/shadowyLogo.jpg" class="w-16 md:w-20 rounded-full" />
      <div class="text-center">
        <a class="text-xl md:text-4xl ml-3 font-extrabold text-center text-white">SHADOWY</a>
        <p class="text-lg md:text-xl ml-2 font-bold text-neutral-400">CREATORS</p>
      </div>
    </div>
    <div class="navbar-center hidden lg:flex">
      <ul class="menu text-md menu-horizontal px-1">
        <li>
          <router-link to="/">{{ $t('message.navbar.home') }}</router-link>
        </li>
        <li>
          <router-link to="/about">{{ $t('message.navbar.about') }}</router-link>
        </li>
        <li tabindex="0">
          <router-link to="/services" @click="toggleMenu()" @mouseover="openMenu">
            {{ $t('message.navbar.services') }}
            <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
              <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </router-link>
          <ul
            class="p-2 shadow z-50 text-base bg-base-200"
            v-show="isMenuOpen"
            @mouseleave="closeMenu"
            @click="toggleMenu()"
          >
            <li>
              <router-link to="/services/consulting">{{ $t('message.navbar.servicesOptions.consulting') }}</router-link>
            </li>
            <li>
              <router-link to="/services/projectDevelopment">{{
                $t('message.navbar.servicesOptions.development')
              }}</router-link>
            </li>
            <li>
              <router-link to="/services/PoC&MVP">{{ $t('message.navbar.servicesOptions.poc') }}</router-link>
            </li>
            <li>
              <router-link to="/services/audit">{{ $t('message.navbar.servicesOptions.audit') }}</router-link>
            </li>
            <li>
              <router-link to="services/extendedTeam">{{ $t('message.navbar.servicesOptions.extended') }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="/clients">{{ $t('message.navbar.clients') }}</router-link>
        </li>
        <li >
          <router-link to="/contact" ref="pointer">{{ $t('message.navbar.contact') }}</router-link>
        </li>
      </ul>
    </div>
    <div class="navbar-end">
      <LanguageCheckbox />
    </div>
  </div>
</template>
