<script setup lang="ts">
import { computed } from 'vue';

type CustomerReviewTypes = "DeCash" | "PV01";

const props = defineProps<{
  customer: CustomerReviewTypes
}>()

const logoSrc = computed(() => new URL(`/src/assets/clientsLogo/${props.customer}-logo-customers.webp`, import.meta.url).href);
const descriptionKey = computed(() => `message.reviews.${props.customer}.description`);
const reviewKey = computed(() => `message.reviews.${props.customer}.review`);
const teamKey = computed(() => `message.reviews.${props.customer}.team`);
const logoClass = computed(() => {
  return props.customer === "DeCash" ? "shadow-customSmall shadow-secondary bg-white bg-opacity-90 backdrop-blur-md rounded px-2 py-3 md:px-4 md:py-3 w-2/3 md:w-1/5"
    : "px-2 py-3 md:px-4 md:py-3 w-2/3 md:w-1/5"
});

</script>

<template>
  <div
    class="flex-1 flex flex-col items-center w-full border-2 rounded-md border-secondary gap-7 px-4 py-6 md:px-10 md:py-12 bg-white bg-opacity-5 backdrop-blur-md">
    <img :src="logoSrc" :class="logoClass" />
    <h5>{{ $t(descriptionKey) }}</h5>
    <div class="flex gap-2 md:gap-4">
      <img class="max-h-5 md:max-h-6" v-for="n in 5" :key="n" src="/src/assets/images/elements/Star.png" alt="Star" />
    </div>
    <p class="italic text-base text-center">{{ $t(reviewKey) }}</p>
    <p class="italic text-base text-center">{{ $t(teamKey) }}</p>
  </div>
</template>