<script setup lang="ts">
import { addDoc, collection } from 'firebase/firestore'
import 'firebase/functions'
import { ref } from 'vue'
import { sendTelegramMessage } from '../composables/telegramBot'
import { db } from '../firebase'
interface ClientInfo {
  name: string
  company: string
  phone?: string
  email: string
  service: string
  description: string
}

const clientInfo = ref<ClientInfo>({
  name: '',
  company: '',
  phone: '',
  email: '',
  service: '',
  description: ''
})

const submitForm = async () => {
  if (
    clientInfo.value.name === '' ||
    clientInfo.value.email === '' ||
    clientInfo.value.company === '' ||
    clientInfo.value.description === '' ||
    clientInfo.value.service === ''
  )
    return
  try {
    const docRef = await addDoc(collection(db, 'formInput'), clientInfo.value)

    console.log('Document written with ID: ', docRef)
    const telegramNotification = await sendTelegramMessage(clientInfo.value)

    console.log('Telegram Notification: ', telegramNotification)

    clientInfo.value = { name: '', company: '', phone: '', email: '', service: '', description: '' }
  } catch (error) {
    console.log(error)
  }
}
</script>
<template>
  <div class="hero text-center p-8 md:p-24">
    <div>
      <h1 class="text-3xl md:text-5xl font-bold">{{ $t('message.contact.title') }}</h1>
      <p class="py-6 font-thin whitespace-pre-line text-sm md:text-xl">
        {{ $t('message.contact.subTitle') }}
      </p>
    </div>
  </div>
  <div class="hero items-center p-4">
    <div class="w-auto">
      <div class="grid grid-cols-1 sm:grid-cols-4 gap-8">
        <div class="sm:col-start-1 sm:col-span-2 pl-3 sm:pl-0">
          <label class="block ml-2 text-sm font-medium leading-6 text-white">{{
            $t('message.contact.form.name')
          }}</label>
          <div class="mt-2">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autocomplete="given-name"
              required
              v-model="clientInfo.name"
              class="input input-primary bg-slate-700"
            />
          </div>
        </div>

        <div class="sm:col-end-5 sm:col-span-2 pl-3">
          <label class="block ml-2 text-sm font-medium leading-6 text-white">{{
            $t('message.contact.form.company')
          }}</label>
          <div class="mt-2">
            <input
              type="text"
              name="company"
              id="company"
              v-model="clientInfo.company"
              autocomplete="company"
              class="input input-primary bg-slate-700"
            />
          </div>
        </div>

        <div class="sm:col-start-1 sm:col-span-2 pl-3 sm:pl-0">
          <label class="block ml-2 text-sm font-medium leading-6 text-white">Email</label>
          <div class="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              v-model="clientInfo.email"
              class="input input-primary bg-slate-700"
            />
          </div>
        </div>
        <div class="sm:col-end-5 sm:col-span-2 pl-3">
          <label class="block ml-2 text-sm font-medium leading-6 text-white">{{
            $t('message.contact.form.phone')
          }}</label>
          <div class="mt-2">
            <input
              id="number"
              name="phone"
              type="phone"
              autocomplete="phone"
              v-model="clientInfo.phone"
              class="input input-primary bg-slate-700"
            />
          </div>
        </div>

        <div class="sm:col-start-1 sm:col-span-4">
          <label class="block ml-2 text-sm font-medium leading-6 text-white">{{
            $t('message.contact.form.lookingFor')
          }}</label>
          <div class="mt-2">
            <select
              id="service"
              name="service"
              autocomplete="service"
              placeholder=""
              v-model="clientInfo.service"
              class="input input-primary bg-slate-700 w-full"
            >
              <!-- <option>{{ $t('message.contact.form.options.select') }}</option> -->
              <option>{{ $t('message.contact.form.options.consulting') }}</option>
              <option>{{ $t('message.contact.form.options.development') }}</option>
              <option>{{ $t('message.contact.form.options.poc') }}</option>
              <option>{{ $t('message.contact.form.options.audit') }}</option>
              <option>{{ $t('message.contact.form.options.extended') }}</option>
            </select>
          </div>
        </div>
        <div class="sm:col-start-1 sm:col-end-5">
          <label class="block ml-2 text-sm font-medium leading-6 text-white">{{
            $t('message.contact.form.description')
          }}</label>
          <div class="mt-2">
            <textarea
              rows="6"
              class="textarea textarea-primary textarea-bordered textarea-sm w-full bg-slate-700"
              placeholder=""
              name="bio"
              id="bio"
              required
              v-model="clientInfo.description"
            ></textarea>
          </div>
        </div>
        <div class="sm:col-span-3">
          <label class="cursor-pointer label">
            <input type="checkbox" class="checkbox checkbox-primary" />
            <span class="label-text ml-2">{{ $t('message.contact.form.policy') }}</span>
          </label>
        </div>
      </div>
      <div class="my-8">
        <button type="submit" class="btn btn-primary w-28 font-medium text-lg tracking-wider" @click="submitForm()">
          {{ $t('message.contact.form.sendButton') }}
        </button>
      </div>
    </div>
  </div>
</template>