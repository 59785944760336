// import 'dotenv/config'
import { z } from 'zod'
// require('dotenv').config()

const envSchema = z.object({
  VITE_BOT_TOKEN: z.string(),
  VITE_GROUP_ID: z.string()
})

export const validatedENV = envSchema.parse(import.meta.env)
