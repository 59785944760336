<script setup lang="ts">
import { computed } from "vue";

type CustomerTypes = "propeller" | "bip" | "decash" | "pv01" | "labtrace";

const props = defineProps<{
    customer: CustomerTypes
    logo: string
}>()

const logoSrc = computed(() => new URL(props.logo, import.meta.url).href);
const Title = computed(() => `message.customers.${props.customer}.title`);
const CustomerDescription = computed(() => `message.customers.${props.customer}.firstBody`);
const CustomerCollaboration = computed(() => `message.customers.${props.customer}.secondBody`);

</script>

<template>
    <div class="p-6 lg:py-14 lg:px-24">
      <div class="card bg-slate-800 shadow-xlh-max px-1 py-5 lg:p-16 max-w-full overflow-x-hidden md:flex md:flex-col">
        <div class="md:grid md:grid-cols-6 md:gap-2">
          <div class="flex flex-col items-center md:col-span-1 md:w-full">
            <img :src="logoSrc" class="rounded-xl w-2/3 md:w-full" />
          </div>
          <div class="md:col-span-5 px-2 mt-5 md:mt-0 lg:ml-10">
            <h2 class="text-3xl md:text-5xl text-center md:text-left font-bold">
              {{ $t(Title) }}
            </h2>
            <p class="py-4 md:py-6 font-thin md:text-md text-justify md:text-xl leading-relaxed">
              {{ $t(CustomerDescription) }}
            </p>
            <p class="py-4 md:py-6 font-thin md:text-md md:text-xl leading-relaxed">
              {{ $t(CustomerCollaboration) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  

