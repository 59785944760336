<script setup lang="ts">
import { computed } from 'vue';

type WhyShadowyTypes = 'whyShadowy1'

const props = defineProps<{
  section: WhyShadowyTypes;
}>();

const titleKey = computed(() => `message.whyShadowy.${props.section}.title`);
const subTitleKey = computed(() => `message.whyShadowy.${props.section}.body`);
const logoSrc = computed(() => new URL(`/src/assets/images/elements/shadowy-creators-${props.section}-logo.png`, import.meta.url).href);
</script>

<template>
  <div class="flex flex-col items-center w-full">
    <div class="flex flex-col items-center md:w-2/3 gap-7 px-2 py-4 md:px-10 md:py-12">
      <img :src="logoSrc" alt="Logo Shadowy Creators" class="px-4 md:px-6 w-1/3 md:w-1/5">
      <h2>
        {{ $t(titleKey) }}
      </h2>
      <h5>
        {{ $t(subTitleKey) }}
      </h5>
    </div>
  </div>
</template>
