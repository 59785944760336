<template>
  <div>
    <div class="hero hero-with-opacity">
      <div class="hero-content flex-col backdrop-filter backdrop-blur-md lg:flex-row">
        <div>
          <h1 class="text-4xl lg:text-6xl font-bold text-center text-primary">{{ $t('message.aboutUs.title') }}</h1>
          <p class="py-6 font-thin text-center whitespace-pre-line text-lg lg:text-xl">
            {{ $t('message.aboutUs.subtitle') }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="hero min-h-screen max-w-full">
        <div class="hero-content flex-col lg:flex-row-reverse">
          <div class="ml-5 lg:ml-10">
            <h1 class="text-3xl md:text-5xl font-bold">{{ $t('message.aboutUs.firstBlockTitle') }}</h1>
            <p class="py-4 lg:py-6 font-thin text-lg whitespace-pre-line lg:text-xl leading-relaxed">
              {{ $t('message.aboutUs.firstBlockBody') }}
            </p>
          </div>
          <img src="/src/assets/bitcoin.jpeg" class="lg:max-w-xl rounded-lg shadow-2xl" />
        </div>
      </div>
    </div>
    <div>
      <div class="hero min-h-screen">
        <div class="hero-content flex-col lg:flex-row">
          <div>
            <h1 class="text-3xl md:text-5xl font-bold">{{ $t('message.aboutUs.secondBlockTitle') }}</h1>
            <p class="py-4 lg:py-6 font-thin text-lg lg:text-xl whitespace-pre-line leading-relaxed text-justify">
              {{ $t('message.aboutUs.secondBlockBody') }}
            </p>
          </div>
          <img src="/src/assets/bc.jpeg" class="lg:max-w-xl ml-5 lg:ml-10 rounded-lg shadow-2xl" />
        </div>
      </div>
    </div>
  </div>
</template>